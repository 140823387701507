var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container alarm-detail-comp",
    },
    [
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c("el-col", { staticClass: "title-line", attrs: { span: 12 } }, [
                _c("span", [_vm._v("告警设备")]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "span",
                  {
                    staticClass: "jump-to-device",
                    on: { click: _vm.jumpToDevice },
                  },
                  [
                    _vm._v("\n          查看设备详情\n          "),
                    _c("i", { staticClass: "iconfont icon-external-link" }),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "detail-line", attrs: { gutter: 48 } },
            [
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("产品名称"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.detailInfo ? _vm.detailInfo.productName || "-" : "-"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("产品标识码"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo ? _vm.detailInfo.productKey : "-") +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("设备名称"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.detailInfo
                          ? _vm.detailInfo.deviceDescription || "-"
                          : "-"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("设备标识码"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo ? _vm.detailInfo.deviceName : "-") +
                      "\n        "
                  ),
                ]),
              ]),
              ["property", "event"].includes(_vm.detailInfo.dataSource)
                ? _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("模块名称"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.detailInfo ? _vm.detailInfo.moduleName : "-"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.detailInfo.dataSource === "property"
                ? [
                    _c(
                      "el-col",
                      { staticClass: "detail-col", attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v("属性名称"),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.detailInfo
                                  ? _vm.detailInfo.propertyName
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "detail-col", attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v("属性描述"),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.detailInfo
                                  ? _vm.detailInfo.propertyDescription
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm.detailInfo.dataSource === "event"
                ? [
                    _c(
                      "el-col",
                      { staticClass: "detail-col", attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v("事件名称"),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.detailInfo ? _vm.detailInfo.eventName : "-"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "detail-col", attrs: { span: 12 } },
                      [
                        _c("div", { staticClass: "detail-label" }, [
                          _vm._v("事件描述"),
                        ]),
                        _c("div", { staticClass: "detail-value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.detailInfo
                                  ? _vm.detailInfo.eventDescription
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card-container",
          style: {
            backgroundColor:
              _vm.detailInfo.alarmStatus === "alarm" ? "#FEF0F0" : "#F0F9EB",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c("el-col", { staticClass: "title-line", attrs: { span: 12 } }, [
                _c("span", [_vm._v(" 告警信息 ")]),
              ]),
              _vm.detailInfo.ruleKey && _vm.detailInfo.ruleVersion
                ? _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "span",
                      {
                        staticClass: "jump-to-device",
                        on: {
                          click: function ($event) {
                            return _vm.openTriggerHistory("alarm")
                          },
                        },
                      },
                      [
                        _vm._v("\n          告警规则\n          "),
                        _c("i", { staticClass: "iconfont icon-external-link" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "detail-line", attrs: { gutter: 48 } },
            [
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [_vm._v("告警ID")]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo ? _vm.detailInfo.alarmUUID : "-") +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("告警时间"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.detailInfo.alarmTimestamp,
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("告警级别"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.detailInfo
                          ? _vm.AlarmLevelEmuObj[_vm.detailInfo.alarmLevel]
                          : "-"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("告警类型"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.detailInfo
                          ? _vm.AlarmTypeEmuObj[_vm.detailInfo.alarmType]
                          : "-"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [
                  _vm._v("告警内容"),
                ]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.detailInfo ? _vm.detailInfo.alarmContent : "-"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("el-col", { staticClass: "detail-col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "detail-label" }, [_vm._v("告警值")]),
                _c("div", { staticClass: "detail-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo ? _vm.detailInfo.alarmValue : "-") +
                      "\n        "
                  ),
                ]),
              ]),
              _vm.alarmImgList && _vm.alarmImgList.length
                ? _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 24 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("图片"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "alarm-image-line" },
                        [
                          _c(
                            "viewer",
                            { attrs: { images: _vm.alarmImgList } },
                            _vm._l(_vm.alarmImgList, function (item, index) {
                              return _c("img", {
                                key: index,
                                staticClass: "alarm-image",
                                attrs: { src: _vm.imageRender(item), alt: "" },
                              })
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.detailInfo.alarmStatus === "normal"
        ? _c(
            "div",
            {
              staticClass: "card-container",
              style: {
                backgroundColor:
                  _vm.detailInfo.alarmStatus === "alarm"
                    ? "#FEF0F0"
                    : "#F0F9EB",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "title-line", attrs: { span: 12 } },
                    [_c("span", [_vm._v(" 恢复信息 ")])]
                  ),
                  _vm.detailInfo.ruleNormalKey &&
                  _vm.detailInfo.ruleNormalVersion
                    ? _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "span",
                          {
                            staticClass: "jump-to-device",
                            on: {
                              click: function ($event) {
                                return _vm.openTriggerHistory("normal")
                              },
                            },
                          },
                          [
                            _vm._v("\n          恢复规则\n          "),
                            _c("i", {
                              staticClass: "iconfont icon-external-link",
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "detail-line", attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.detailInfo.alarmStatus === "alarm"
                                ? "告警状态"
                                : "恢复状态"
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.detailInfo.alarmStatus === "alarm"
                                ? "未恢复"
                                : "恢复"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("恢复时间"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.normalTimeRender(
                                _vm.detailInfo.normalTimestamp
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("恢复描述"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.detailInfo
                                ? _vm.detailInfo.alarmStatus === "alarm"
                                  ? "-"
                                  : _vm.detailInfo.normalContent
                                : "-"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("恢复值"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.detailInfo
                                ? _vm.detailInfo.alarmStatus === "alarm"
                                  ? "-"
                                  : _vm.detailInfo.normalValue
                                : "-"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm.normalImgList && _vm.normalImgList.length
                    ? _c(
                        "el-col",
                        { staticClass: "detail-col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "detail-label" }, [
                            _vm._v("图片"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "alarm-image-line" },
                            [
                              _c(
                                "viewer",
                                { attrs: { images: _vm.normalImgList } },
                                _vm._l(
                                  _vm.normalImgList,
                                  function (item, index) {
                                    return _c("img", {
                                      key: index,
                                      staticClass: "alarm-image",
                                      attrs: {
                                        src: _vm.imageRender(item),
                                        alt: "",
                                      },
                                    })
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.detailInfo.handleTime
        ? _c(
            "div",
            {
              staticClass: "card-container",
              staticStyle: { background: "#f0f9eb" },
            },
            [
              _vm._m(0),
              _c(
                "el-row",
                { staticClass: "detail-line", attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("确认类型"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.ConfirmMaps[_vm.detailInfo.handleType]) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("确认时间"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.detailInfo.handleTime,
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("确认人姓名"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.detailInfo.userName || "-") +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("确认人手机号"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.detailInfo.phone || "-") +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detail-col", attrs: { span: 24 } },
                    [
                      _c("div", { staticClass: "detail-label" }, [
                        _vm._v("确认描述"),
                      ]),
                      _c("div", { staticClass: "detail-value" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.detailInfo.handleComment || "-") +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("RuleHistoryDrawer", {
        attrs: { show: _vm.detailDrawer.show, dataset: _vm.detailDrawer.datas },
        on: { close: _vm.ruleHistoryDrawerClose },
      }),
      _c("AlarmImageDialog", {
        attrs: { show: _vm.alarmDialogShow, url: _vm.alarmDialogUrl },
        on: { close: _vm.previewCloseHandler },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("span", [_vm._v(" 确认信息 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }