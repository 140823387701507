<template>
  <div class="alarm-image-preview">
    <el-dialog
      :visible.sync="show"
      :before-close="dialogCloseHandler"
      title="图片预览"
      width="1000px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="dialog-container">
        <img :src="url" class="alarm-image" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "alarm-image-preview",
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    url: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    dialogCloseHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-image-preview {
  .dialog-container {
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
  }
  .alarm-image {
    height: 100%;
    width: 100%;
  }
}
</style>