var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alarm-image-preview" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            "before-close": _vm.dialogCloseHandler,
            title: "图片预览",
            width: "1000px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-container" }, [
            _c("img", {
              staticClass: "alarm-image",
              attrs: { src: _vm.url, alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }