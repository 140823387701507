<template>
  <div class="app-container alarm-detail-comp" v-loading="loading">
    <div class="card-container">
      <el-row :gutter="48">
        <el-col :span="12" class="title-line">
          <span>告警设备</span>
        </el-col>
        <el-col :span="12">
          <span class="jump-to-device" @click="jumpToDevice">
            查看设备详情
            <i class="iconfont icon-external-link"></i>
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="48" class="detail-line">
        <el-col :span="12" class="detail-col">
          <div class="detail-label">产品名称</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.productName || "-" : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">产品标识码</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.productKey : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">设备名称</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.deviceDescription || "-" : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">设备标识码</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.deviceName : "-" }}
          </div>
        </el-col>
        <el-col
          :span="12"
          class="detail-col"
          v-if="['property', 'event'].includes(detailInfo.dataSource)"
        >
          <div class="detail-label">模块名称</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.moduleName : "-" }}
          </div>
        </el-col>
        <template v-if="detailInfo.dataSource === 'property'">
          <el-col :span="12" class="detail-col">
            <div class="detail-label">属性名称</div>
            <div class="detail-value">
              {{ detailInfo ? detailInfo.propertyName : "-" }}
            </div>
          </el-col>
          <el-col :span="12" class="detail-col">
            <div class="detail-label">属性描述</div>
            <div class="detail-value">
              {{ detailInfo ? detailInfo.propertyDescription : "-" }}
            </div>
          </el-col>
        </template>
        <template v-if="detailInfo.dataSource === 'event'">
          <el-col :span="12" class="detail-col">
            <div class="detail-label">事件名称</div>
            <div class="detail-value">
              {{ detailInfo ? detailInfo.eventName : "-" }}
            </div>
          </el-col>
          <el-col :span="12" class="detail-col">
            <div class="detail-label">事件描述</div>
            <div class="detail-value">
              {{ detailInfo ? detailInfo.eventDescription : "-" }}
            </div>
          </el-col>
        </template>
      </el-row>
    </div>

    <div
      class="card-container"
      :style="{
        backgroundColor:
          detailInfo.alarmStatus === 'alarm' ? '#FEF0F0' : '#F0F9EB',
      }"
    >
      <el-row :gutter="48">
        <el-col :span="12" class="title-line">
          <span> 告警信息 </span>
        </el-col>
        <el-col :span="12" v-if="detailInfo.ruleKey && detailInfo.ruleVersion">
          <span class="jump-to-device" @click="openTriggerHistory('alarm')">
            告警规则
            <i class="iconfont icon-external-link"></i>
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="48" class="detail-line">
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警ID</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.alarmUUID : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警时间</div>
          <div class="detail-value">
            {{ detailInfo.alarmTimestamp | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警级别</div>
          <div class="detail-value">
            {{ detailInfo ? AlarmLevelEmuObj[detailInfo.alarmLevel] : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警类型</div>
          <div class="detail-value">
            {{ detailInfo ? AlarmTypeEmuObj[detailInfo.alarmType] : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警内容</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.alarmContent : "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">告警值</div>
          <div class="detail-value">
            {{ detailInfo ? detailInfo.alarmValue : "-" }}
          </div>
        </el-col>
        <el-col
          class="detail-col"
          :span="24"
          v-if="alarmImgList && alarmImgList.length"
        >
          <div class="detail-label">图片</div>
          <div class="alarm-image-line">
            <viewer :images="alarmImgList">
              <img
                v-for="(item, index) in alarmImgList"
                :key="index"
                class="alarm-image"
                :src="imageRender(item)"
                alt=""
              />
            </viewer>
          </div>
        </el-col>
      </el-row>
    </div>

    <div
      class="card-container"
      :style="{
        backgroundColor:
          detailInfo.alarmStatus === 'alarm' ? '#FEF0F0' : '#F0F9EB',
      }"
      v-if="detailInfo.alarmStatus === 'normal'"
    >
      <el-row :gutter="48">
        <el-col :span="12" class="title-line">
          <span> 恢复信息 </span>
        </el-col>
        <el-col
          :span="12"
          v-if="detailInfo.ruleNormalKey && detailInfo.ruleNormalVersion"
        >
          <span class="jump-to-device" @click="openTriggerHistory('normal')">
            恢复规则
            <i class="iconfont icon-external-link"></i>
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="48" class="detail-line">
        <el-col :span="12" class="detail-col">
          <div class="detail-label">
            {{ detailInfo.alarmStatus === "alarm" ? "告警状态" : "恢复状态" }}
          </div>
          <div class="detail-value">
            {{ detailInfo.alarmStatus === "alarm" ? "未恢复" : "恢复" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">恢复时间</div>
          <div class="detail-value">
            {{ normalTimeRender(detailInfo.normalTimestamp) }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">恢复描述</div>
          <div class="detail-value">
            {{
              detailInfo
                ? detailInfo.alarmStatus === "alarm"
                  ? "-"
                  : detailInfo.normalContent
                : "-"
            }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">恢复值</div>
          <div class="detail-value">
            {{
              detailInfo
                ? detailInfo.alarmStatus === "alarm"
                  ? "-"
                  : detailInfo.normalValue
                : "-"
            }}
          </div>
        </el-col>
        <el-col
          class="detail-col"
          :span="24"
          v-if="normalImgList && normalImgList.length"
        >
          <div class="detail-label">图片</div>
          <div class="alarm-image-line">
            <viewer :images="normalImgList">
              <img
                v-for="(item, index) in normalImgList"
                :key="index"
                class="alarm-image"
                :src="imageRender(item)"
                alt=""
              />
            </viewer>
          </div>
        </el-col>
      </el-row>
    </div>

    <div
      class="card-container"
      style="background: #f0f9eb"
      v-if="detailInfo.handleTime"
    >
      <div class="title-line">
        <span> 确认信息 </span>
      </div>
      <el-row :gutter="48" class="detail-line">
        <el-col :span="12" class="detail-col">
          <div class="detail-label">确认类型</div>
          <div class="detail-value">
            {{ ConfirmMaps[detailInfo.handleType] }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">确认时间</div>
          <div class="detail-value">
            {{ detailInfo.handleTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">确认人姓名</div>
          <div class="detail-value">
            {{ detailInfo.userName || "-" }}
          </div>
        </el-col>
        <el-col :span="12" class="detail-col">
          <div class="detail-label">确认人手机号</div>
          <div class="detail-value">
            {{ detailInfo.phone || "-" }}
          </div>
        </el-col>
        <el-col :span="24" class="detail-col">
          <div class="detail-label">确认描述</div>
          <div class="detail-value">
            {{ detailInfo.handleComment || "-" }}
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 规则配置抽屉 -->
    <RuleHistoryDrawer
      :show="detailDrawer.show"
      :dataset="detailDrawer.datas"
      @close="ruleHistoryDrawerClose"
    />
    <AlarmImageDialog
      :show="alarmDialogShow"
      :url="alarmDialogUrl"
      @close="previewCloseHandler"
    />
  </div>
</template>

<script>
import { findAlarmDetail } from "@/api/ruge/vlink/alarm/alarm";
import { deepClone } from "@/utils/utils.js";
import { dateFormat } from "@/filters/index.js";
import RuleHistoryDrawer from "../rule/release/components/ruleHistoryDrawer.vue";
import { findTsl } from "@/api/ruge/vlink/product/product";
import { getRuleHistoryDetail } from "@/api/ruge/vlink/rule/rule";
import { envInfo } from "@/constants/envInfo";
import AlarmImageDialog from "./alarmImageDialog.vue";
const AlarmLevelEmuObj = {
  info: "提示",
  warn: "一般",
  error: "严重",
};
const AlarmTypeEmuObj = {
  run: "运行",
  fault: "故障",
};
const ConfirmMaps = {
  handle: "处理",
  noHandle: "无需处理",
  misreport: "误报",
};
export default {
  name: "alarmDetail",
  components: {
    RuleHistoryDrawer,
    AlarmImageDialog,
  },
  data() {
    return {
      AlarmLevelEmuObj,
      AlarmTypeEmuObj,
      ConfirmMaps,
      alarmImgList: [],
      normalImgList: [],
      alarmDialogShow: false,
      alarmDialogUrl: "",
      alarmState: "alarm",
      detailInfo: {},
      loading: false,
      freshIns: null,
      detailDrawer: {
        show: false,
        datas: {},
      },
    };
  },
  watch: {
    $route(to, from) {
      console.log("to, from", to, from);
      // 路由参数发生变化，打开指定页面
      // if (to.query != from.query) {
      //   this.forwardPage();
      // }
      if (to.query.alarmId !== from.query.alarmId) {
        this.alarmImgList = [];
        this.normalImgList = [];
        this.getAlarmDetailById(to.query.alarmId);
      }
    },
  },
  created() {
    this.freshDatas(true);
    this.startFresh();
  },
  beforeDestroy() {
    console.log("destroy", this.freshIns);
    if (this.freshIns) window.clearInterval(this.freshIns);
  },
  methods: {
    imagePreviewHandler(fileId) {
      this.$store.commit("setVedioSrc", this.imageRender(fileId));
      // this.alarmDialogUrl = this.imageRender(fileId);
      // this.alarmDialogShow = true;
    },
    previewCloseHandler() {
      this.alarmDialogShow = false;
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
    ruleHistoryDrawerClose() {
      this.detailDrawer.datas = {};
      this.detailDrawer.show = false;
    },
    openTriggerHistory(type) {
      const { ruleKey, ruleVersion, ruleNormalKey, ruleNormalVersion } =
        this.detailInfo;
      getRuleHistoryDetail({
        ruleKey: type === "alarm" ? ruleKey : ruleNormalKey,
        ruleVersion: type === "alarm" ? ruleVersion : ruleNormalVersion,
      })
        .then((res) => {
          this.detailDrawer.datas = res;
        })
        .finally(() => {
          this.detailDrawer.show = true;
        });
    },
    startFresh() {
      if (this.freshIns) window.clearInterval(this.freshIns);
      this.freshIns = window.setInterval(() => {
        if (this.detailInfo && this.detailInfo.alarmStatus === "normal") {
          if (this.freshIns) window.clearInterval(this.freshIns);
          return;
        }
        this.freshDatas();
      }, 5000);
    },
    freshDatas(freshLoading) {
      const { alarmId } = this.$route.query;
      alarmId && this.getAlarmDetailById(alarmId, freshLoading);
    },
    normalTimeRender(time) {
      return this.detailInfo.alarmStatus === "alarm"
        ? "-"
        : dateFormat(time, "YYYY-MM-DD HH:mm:ss");
    },
    jumpToDevice() {
      const { origin, pathname } = window.location;
      const { productKey, deviceName } = this.detailInfo;
      const targetUrl = `${origin}${pathname}#/iot/device/detail?productKey=${productKey}&deviceName=${deviceName}`;
      window.open(targetUrl, "_blank");
    },
    freshImageList(res) {
      const { alarmImgList, normalImgList } = res;
      if (alarmImgList && alarmImgList.length !== this.alarmImgList.length) {
        this.alarmImgList = alarmImgList;
      }
      // else {
      //   this.alarmImgList = [];
      // }
      if (normalImgList && normalImgList.length !== this.normalImgList.length) {
        this.normalImgList = normalImgList;
      }
      //  else {
      //   this.normalImgList = [];
      // }
    },
    getAlarmDetailById(alarmUUID, freshLoading) {
      if (freshLoading) this.loading = true;
      findAlarmDetail({ alarmUUID })
        .then((res) => {
          if (res) {
            this.$eventBus.$emit("setDetailAlias", res.alarmUUID);
            this.detailInfo = deepClone(res);
            this.freshImageList(res);
            // this.getPropDesc();
          } else {
            console.log("aaa2");
            this.$message.warning("获取告警详情失败！");
          }
        })
        .catch(() => {
          console.log("aaa1");
          this.$message.warning("获取告警详情失败！");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // getPropDesc() {
    //   const { productKey } = this.detailInfo;
    //   findTsl({ productKey }).then((response) => {
    //     if (response && response.modules && response.modules.length > 0) {
    //       response.modules.forEach((item) => {
    //         if (item.properties && item.properties.length > 0) {
    //           const findList = item.properties.find(
    //             (item) => item.propertyName === this.detailInfo.propertyName
    //           );

    //           this.detailInfo.propertyDescription = findList.description;
    //           console.log("findList", this.detailInfo.propertyDescription);
    //         }
    //       });
    //     }
    //     this.$forceUpdate();
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.alarm-detail-comp {
  padding: 40px 24px;
  .card-container + .card-container {
    margin-top: 24px;
  }
  .card-container {
    padding: 32px;
    background-color: #f8f8f8;
    .title-line {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
    .detail-line {
      font-size: 12px;
      color: #000000;
      .detail-col {
        margin-top: 20px;
        .detail-label {
          height: 18px;
          line-height: 18px;
          opacity: 0.7;
          font-weight: 300;
          color: #4a4a4a;
        }
        .detail-value {
          // height: 20px;
          line-height: 20px;
          font-size: 14px;
          font-weight: normal;
          margin-top: 4px;
          color: #000000;
        }
        .alarm-image-line {
          margin-top: 4px;
          .alarm-image {
            cursor: pointer;
            width: 120px;
            height: 120px;
          }
          .alarm-image + .alarm-image {
            margin-left: 10px;
          }
        }
      }
    }
    .jump-to-device {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #0486fe;
      cursor: pointer;
      .iconfont {
        margin-left: 6px;
      }
    }
  }
}
</style>